import * as React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import LogoBV from "../components/LogoBV"
import LogoMQB from "../components/LogoMQB"
import LogoVector from "../components/LogoVector"
import LogoTM from "../components/LogoTM"
import LogoFF from "../components/LogoFF"
import LogoSD from "../components/LogoSD"
import Layout from "../components/Layout"
import "../components/Layout.css"
import Seo from "../components/Seo"

const RefriedBeans = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2.5%;
  max-width: 1344px;
  margin: 0 auto;

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 18px;
  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const BeanCaption = styled.div`
    background: white;
`

const BeanGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    
    &:hover {
        transform: rotate(0.02turn) scale(1.1, 1.1);
        transition: 2s cubic-bezier(0.2, 0.8, 0.2, 1)
    }
`

const Work = () => (
    <Layout>
        <Seo title="Work" />
        <div className="Beans">
        <h1 className="hideMe">Work</h1>
            <RefriedBeans>
                <Link to="/bv" className="Bean">
                    <BeanGroup>
                        <LogoBV />
                    </BeanGroup>
                    <BeanCaption>
                        <h3 className="labels">BaleVision™ Design System</h3>
                        <h5>Product design of a b2b data analytics design system #designops</h5>
                    </BeanCaption>
                </Link>
                <Link to="/mqb" className="Bean Bean2">
                    <BeanGroup>
                        <LogoMQB />
                    </BeanGroup>
                    <BeanCaption>
                        <h3 className="labels">merQbiz Data Analytics</h3>
                        <h5>Building a data and analytics product from the ground up #pivot</h5>
                    </BeanCaption>
                </Link>
                <Link to="/ltt" className="Bean Bean3">
                    <BeanGroup>
                        <LogoVector />
                    </BeanGroup>
                    <BeanCaption>
                        <h3 className="labels">Fun with Vector Animations</h3>
                        <h5>A gallery of some light animations used for marketing purposes</h5>
                    </BeanCaption>
                </Link>
                <Link to="/tm" className="Bean Bean4">
                    <BeanGroup>
                        <LogoTM />
                    </BeanGroup>
                    <BeanCaption>
                        <h3 className="labels">Thrive Market Feature</h3>
                        <h5>Full product process for adding user-based automation to an eCommerce platform</h5>
                    </BeanCaption>
                </Link>
                <Link to="/ff" className="Bean Bean5">
                    <BeanGroup>
                        <LogoFF />
                    </BeanGroup>
                    <BeanCaption>
                        <h3 className="labels">Ford Factory Website</h3>
                        <h5>Design process for a commercial real estate project in the Downtown LA Arts District</h5>
                    </BeanCaption>
                </Link>
                <Link to="/sd" className="Bean Bean6">
                    <BeanGroup>
                        <LogoSD />
                    </BeanGroup>
                    <BeanCaption>
                        <h3 className="labels">Salad Days Mobile App</h3>
                        <h5>Building a data and analytics product from the ground up #pivot</h5>
                    </BeanCaption>
                </Link>
            </RefriedBeans>
        </div>
    </Layout>
  )

export default Work