import * as React from "react"

const LogoMQB = () => (
    <svg viewBox="0 0 267 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M30.6303 9.16995L17.3889 28.994L4.14745 9.16995H0V45.5077H3.99525V16.1331L17.2367 35.5386H17.4459L30.6683 16.076V45.5077H34.7777V9.16995H30.6303Z" fill="white"/>
    <path d="M44.119 9.16995H70.3925V12.8989H48.2284V25.3222H68.0524V29.0511H48.2284V41.7598H70.6398V45.5077H44.119V9.16995Z" fill="white"/>
    <path d="M78.4211 9.16995H94.0406C98.5115 9.16995 102.088 10.5207 104.371 12.8037C106.141 14.5731 107.168 17.1034 107.168 19.9762V20.0713C107.168 26.0832 103.02 29.6218 97.3129 30.6682L108.481 45.5077H103.439L92.8991 31.3912H82.5115V45.5077H78.4021V9.16995H78.4211ZM93.6791 27.7003C99.1203 27.7003 103.02 24.9036 103.02 20.2235V20.1284C103.02 15.6575 99.5959 12.975 93.7362 12.975H82.5305V27.7193H93.6791V27.7003Z" fill="white"/>
    <path d="M174.668 9.16995H190.136C194.283 9.16995 197.556 10.3685 199.629 12.3852C201.132 13.9452 201.969 15.8668 201.969 18.2068V18.302C201.969 23.0202 199.059 25.4744 196.205 26.711C200.504 28.0047 203.986 30.497 203.986 35.4815V35.5767C203.986 41.7978 198.735 45.4887 190.802 45.4887H174.649V9.16995H174.668ZM197.822 18.7776C197.822 15.2009 194.968 12.8608 189.774 12.8608H178.721V25.3793H189.47C194.397 25.3793 197.822 23.1533 197.822 18.8917V18.7776ZM190.174 28.994H178.702V41.8169H190.897C196.395 41.8169 199.82 39.3817 199.82 35.3293V35.2342C199.839 31.277 196.509 28.994 190.174 28.994Z" fill="white"/>
    <path d="M216.143 9.16995H212.034V45.5077H216.143V9.16995Z" fill="white"/>
    <path d="M223.981 42.7491L247.477 12.8608H224.742V9.16995H253.09V11.9286L229.518 41.8168H253.09V45.5077H223.981V42.7491Z" fill="white"/>
    <path d="M261.689 17.7693C258.949 17.7693 257.009 15.7146 257.009 13.013C257.009 10.1593 259.178 8.25679 261.689 8.25679C264.181 8.25679 266.35 10.1593 266.35 13.013C266.35 15.8668 264.181 17.7693 261.689 17.7693ZM261.689 9.07486C259.615 9.07486 258.017 10.692 258.017 13.013C258.017 15.1819 259.425 16.9512 261.689 16.9512C263.725 16.9512 265.323 15.3531 265.323 13.013C265.323 10.692 263.725 9.07486 261.689 9.07486ZM260.738 15.7336H259.862V10.4637H261.86C263.135 10.4637 263.782 10.9013 263.782 11.9857C263.782 12.9369 263.192 13.3365 262.412 13.4316L263.896 15.7526H262.906L261.537 13.4887H260.719V15.7336H260.738ZM261.708 12.7277C262.355 12.7277 262.925 12.6706 262.925 11.8906C262.925 11.2437 262.298 11.1866 261.784 11.1866H260.738V12.7277H261.708Z" fill="white"/>
    <path d="M161.37 51.9192L155.415 46.6113C151.23 49.5792 146.055 51.2914 140.328 51.2914C125.089 51.2914 114.473 39.7432 114.473 25.7789V25.6457C114.473 11.6813 125.242 0 140.462 0C155.682 0 166.317 11.5482 166.317 25.5125V25.6457C166.317 31.3151 164.471 36.547 161.294 40.8847L166.963 45.6981L161.37 51.9192ZM149.042 41.0179L140.462 33.6552L146.055 27.2819L154.768 35.2153C156.328 32.5137 157.185 29.3366 157.185 25.7979V25.6647C157.185 16.0381 150.164 8.10465 140.328 8.10465C130.473 8.10465 123.605 15.9049 123.605 25.5316V25.6647C123.605 35.2914 130.626 43.2248 140.462 43.2248C143.734 43.2058 146.569 42.4448 149.042 41.0179Z" fill="white"/>
    </svg>
)

export default LogoMQB