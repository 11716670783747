import * as React from "react"

const LogoSD = () => (
    <svg viewBox="0 0 267 206" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M89.2992 87.7156C86.5337 86.9953 84.2734 85.7036 82.5181 83.8448C80.7586 81.986 79.6409 79.6677 79.1524 76.8981C78.7384 74.5549 78.8543 72.336 79.5042 70.2453C80.1501 68.1547 81.214 66.3994 82.6961 64.9836C84.1782 63.5677 85.9293 62.6818 87.9537 62.3258C89.8167 61.9987 91.4726 62.185 92.9174 62.8929C94.3622 63.6008 95.3889 64.7517 95.9975 66.3456C93.2279 66.8341 91.1456 67.956 89.7504 69.7113C88.3553 71.4707 87.8792 73.5986 88.3222 76.1032C88.7279 78.3967 89.7422 80.1106 91.3733 81.2533C93.0044 82.3959 94.9625 82.7643 97.256 82.3586C100.079 81.8618 102.245 80.5039 103.747 78.2891C105.25 76.0743 105.751 73.5282 105.242 70.651C104.799 68.1464 103.689 65.8985 101.909 63.903C100.129 61.9118 97.5706 59.5603 94.238 56.8487C90.7771 54.0543 88.1069 51.5994 86.2233 49.4839C84.3396 47.3725 83.1473 44.9052 82.6505 42.0777C82.162 39.3081 82.4932 36.6793 83.6524 34.1953C84.8074 31.7114 86.6331 29.5918 89.1212 27.8324C91.6134 26.0729 94.5651 24.8931 97.9764 24.2928C102.29 23.531 105.921 23.9367 108.86 25.5058C111.799 27.0748 113.571 29.567 114.172 32.9741C114.565 35.2137 114.325 37.1222 113.451 38.7037C112.578 40.2851 111.232 41.2373 109.423 41.556C107.399 41.9121 105.627 41.3739 104.112 39.9373C104.989 39.0141 105.619 37.8881 105.987 36.5592C106.355 35.2303 106.426 33.9014 106.19 32.5684C105.888 30.8627 105.142 29.5959 103.954 28.7597C102.762 27.9276 101.288 27.6626 99.5288 27.9731C97.3968 28.3498 95.7864 29.4138 94.6976 31.1732C93.6046 32.9327 93.2693 34.9819 93.6792 37.3292C94.0559 39.4613 95.0163 41.3366 96.5688 42.9594C98.1171 44.5823 100.452 46.6025 103.569 49.0202C106.103 50.937 108.169 52.6053 109.767 54.0253C111.365 55.4494 112.818 57.1551 114.13 59.1463C115.442 61.1418 116.32 63.3897 116.763 65.8943C117.363 69.3056 116.962 72.5637 115.563 75.6644C114.159 78.7693 111.961 81.4106 108.964 83.584C105.97 85.7616 102.472 87.2022 98.4773 87.906C95.124 88.5022 92.0646 88.4359 89.2992 87.7156Z" fill="white"/>
    <path d="M125.734 78.9018C123.345 77.1796 121.756 74.1078 120.977 69.6864C120.282 65.7453 120.34 61.4646 121.143 56.8404C121.95 52.2203 123.644 48.1301 126.231 44.5698C128.818 41.0096 132.267 38.8485 136.585 38.0868C138.771 37.7018 140.46 37.7887 141.656 38.3476C142.853 38.9065 143.56 39.7966 143.776 41.022L143.875 41.5809L144.008 37.1884L155.516 35.1599L154.552 63.3483C154.481 64.1845 154.523 65.0581 154.684 65.9605C155.09 68.254 156.382 69.2062 158.568 68.8212C160.058 68.5562 161.229 67.6372 162.078 66.0599C162.927 64.4826 163.473 62.5162 163.718 60.1647L167.075 59.5727C166.119 65.6749 164.367 70.0093 161.826 72.5719C159.284 75.1345 156.547 76.6745 153.616 77.192C151.377 77.5853 149.468 77.279 147.891 76.2647C146.314 75.2546 145.175 73.5696 144.488 71.2182C143.329 73.6773 141.921 75.7513 140.269 77.4404C138.617 79.1336 136.485 80.21 133.873 80.6695C130.839 81.216 128.127 80.624 125.734 78.9018ZM141.329 69.438C142.414 67.956 143.002 66.0806 143.097 63.812L143.747 45.0708C143.627 44.3794 143.238 43.746 142.588 43.1747C141.938 42.6034 141.052 42.4171 139.934 42.6117C137.802 42.9884 136.105 44.5657 134.834 47.3436C133.563 50.1214 132.76 53.3422 132.416 57.0019C132.072 60.6615 132.134 63.8244 132.606 66.4863C133.074 69.1524 133.761 70.7876 134.668 71.3962C135.571 72.0089 136.585 72.2118 137.703 72.013C139.036 71.7771 140.244 70.9201 141.329 69.438Z" fill="white"/>
    <path d="M167.621 72.2573C165.767 70.9367 164.558 68.6763 163.995 65.4803C163.759 64.1473 163.668 62.3796 163.718 60.173L165.208 18.6211L176.754 14.9366L175.173 59.7176C175.102 60.5539 175.144 61.4274 175.305 62.3299C175.491 63.398 175.88 64.11 176.464 64.4743C177.052 64.8386 177.93 64.9173 179.101 64.7103C180.645 64.4371 181.97 63.5304 183.071 61.9904C184.173 60.4504 184.831 58.5874 185.054 56.4057L188.412 55.8137C187.456 61.9159 185.613 66.2669 182.885 68.8626C180.157 71.4583 177.271 73.0232 174.237 73.5614C171.683 74.0167 169.476 73.5779 167.621 72.2573Z" fill="white"/>
    <path d="M189.116 67.7283C186.727 66.0061 185.137 62.9343 184.359 58.5129C183.663 54.5717 183.721 50.2911 184.524 45.6669C185.328 41.0468 187.025 36.9566 189.612 33.3963C192.2 29.836 195.648 27.675 199.966 26.9133C202.152 26.5283 203.841 26.6152 205.038 27.1741C206.234 27.733 206.942 28.623 207.157 29.8484L207.257 30.4073L207.389 26.0149L218.898 23.9864L217.933 52.1748C217.863 53.011 217.904 53.8845 218.066 54.787C218.471 57.0805 219.763 58.0327 221.949 57.6477C223.439 57.3869 224.611 56.4637 225.46 54.8864C226.308 53.3091 226.855 51.3427 227.099 48.9912L230.456 48.3992C229.5 54.5014 227.749 58.8358 225.207 61.3984C222.665 63.961 219.929 65.501 216.998 66.0185C214.762 66.4118 212.849 66.1054 211.272 65.0912C209.691 64.081 208.556 62.3961 207.869 60.0447C206.71 62.5037 205.303 64.5778 203.651 66.2669C201.999 67.9601 199.867 69.0365 197.255 69.496C194.22 70.0424 191.504 69.4463 189.116 67.7283ZM204.711 58.2604C205.795 56.7783 206.383 54.9029 206.478 52.6343L207.128 33.8931C207.008 33.2017 206.619 32.5683 205.969 31.997C205.319 31.4257 204.433 31.2394 203.315 31.434C201.183 31.8107 199.486 33.388 198.215 36.1659C196.944 38.9437 196.141 42.1646 195.797 45.8242C195.454 49.4839 195.516 52.6467 195.988 55.3087C196.46 57.9747 197.143 59.61 198.049 60.2185C198.952 60.8312 199.966 61.0341 201.084 60.8354C202.417 60.6035 203.626 59.7424 204.711 58.2604Z" fill="white"/>
    <path d="M231.152 60.3138C228.763 58.5916 227.173 55.5198 226.395 51.0984C225.7 47.1573 225.758 42.8766 226.561 38.2524C227.368 33.6323 229.061 29.5421 231.649 25.9818C234.236 22.4215 237.685 20.2605 242.002 19.4988C244.188 19.1138 245.877 19.2007 247.074 19.7596C248.27 20.3185 248.978 21.2086 249.193 22.434L249.251 22.7527L249.926 3.68034L261.476 0L259.895 44.781C259.825 45.6172 259.854 46.4079 259.982 47.1573C260.359 49.2893 261.638 50.1628 263.824 49.7778C264.784 49.6081 265.608 49.2976 266.295 48.8463C266.552 51.5497 265.964 53.7521 264.536 55.4618C263.107 57.1716 261.245 58.2273 258.955 58.6288C256.823 59.0056 254.927 58.7779 253.271 57.9416C251.611 57.1095 250.348 55.615 249.475 53.4623C247.057 58.4508 243.663 61.328 239.295 62.0981C236.26 62.628 233.545 62.036 231.152 60.3138ZM246.411 51.2764C247.43 50.0552 248.076 48.482 248.341 46.5694L248.436 45.2322L249.123 26.2385C248.316 24.4584 247.057 23.7173 245.352 24.0195C243.22 24.3963 241.522 25.9735 240.251 28.7514C238.98 31.5293 238.177 34.7501 237.834 38.4097C237.49 42.0694 237.552 45.2322 238.024 47.8942C238.492 50.5602 239.179 52.1955 240.086 52.804C240.988 53.4126 242.002 53.6196 243.12 53.4209C244.296 53.218 245.393 52.5018 246.411 51.2764Z" fill="white"/>
    <path d="M39.4841 164.759L39.3682 169.846L21.6785 172.964L24.6096 91.8886L42.3572 86.2252L39.7698 157.741C42.3489 158.975 44.8287 159.381 47.205 158.963C51.8748 158.139 55.5137 154.711 58.1218 148.679C60.7299 142.648 62.2824 135.279 62.775 126.581C63.2677 117.883 62.7626 109.272 61.2599 100.756C59.844 92.729 57.2318 87.136 53.4231 83.9607C49.6144 80.7896 44.8825 79.7049 39.2316 80.6985C33.088 81.7831 27.7931 83.9607 23.3469 87.2353C18.9007 90.51 15.6799 94.6457 13.6927 99.6425C11.7015 104.639 11.2419 110.166 12.31 116.227C12.8441 119.257 13.5023 121.315 14.2847 122.404C15.0672 123.492 15.4729 124.113 15.5019 124.279C11.2419 125.028 7.82654 124.705 5.25982 123.298C2.68896 121.894 1.04957 119.183 0.341656 115.171C-0.639493 109.603 0.515531 103.952 3.81087 98.2184C7.1062 92.4888 11.9871 87.4879 18.4577 83.2238C24.9242 78.9597 32.2145 76.1115 40.3204 74.6833C51.5394 72.7044 60.3946 74.5632 66.8901 80.2597C73.3814 85.9561 77.5378 93.9626 79.3594 104.279C81.007 113.615 81.0484 123.087 79.4918 132.691C77.9352 142.296 75.0415 150.43 70.8105 157.083C66.5796 163.74 61.5165 167.59 55.6213 168.629C50.7032 169.49 45.3255 168.203 39.4841 164.759Z" fill="white"/>
    <path d="M93.9317 156.044C90.2555 153.399 87.8213 148.679 86.6207 141.882C85.5526 135.821 85.6395 129.243 86.8774 122.139C88.1152 115.039 90.7233 108.754 94.6976 103.281C98.6719 97.8127 103.979 94.4884 110.611 93.321C113.969 92.729 116.564 92.8614 118.407 93.7184C120.245 94.5754 121.334 95.9457 121.665 97.8293L121.818 98.6904L122.021 91.9424L139.71 88.8251L138.228 132.153C138.117 133.44 138.187 134.778 138.431 136.169C139.052 139.692 141.039 141.153 144.401 140.561C146.694 140.155 148.491 138.744 149.795 136.318C151.099 133.892 151.94 130.874 152.316 127.26L157.475 126.349C156.005 135.73 153.314 142.391 149.406 146.328C145.498 150.269 141.292 152.637 136.788 153.428C133.347 154.032 130.416 153.56 127.986 152.004C125.56 150.447 123.813 147.864 122.758 144.25C120.973 148.029 118.812 151.217 116.275 153.817C113.737 156.417 110.458 158.073 106.447 158.781C101.777 159.6 97.6038 158.69 93.9317 156.044ZM117.902 141.493C119.57 139.216 120.477 136.334 120.621 132.844L121.623 104.043C121.437 102.979 120.841 102.006 119.843 101.129C118.841 100.251 117.483 99.9613 115.765 100.264C112.487 100.843 109.874 103.265 107.929 107.537C105.979 111.805 104.741 116.757 104.211 122.379C103.681 128.005 103.78 132.861 104.501 136.955C105.225 141.05 106.277 143.567 107.668 144.502C109.055 145.442 110.611 145.757 112.333 145.454C114.374 145.09 116.233 143.774 117.902 141.493Z" fill="white"/>
    <path d="M170.399 175.241C167.419 173.655 165.589 170.939 164.91 167.089C164.086 162.42 165.307 158.342 168.578 154.852C171.844 151.362 176.22 148.162 181.705 145.256L182.012 138.363C180.099 141.406 178 143.629 175.715 145.044C173.43 146.46 171.02 147.392 168.483 147.839C164.55 148.53 161.159 147.864 158.311 145.831C155.463 143.798 153.604 140.329 152.739 135.415C152.378 133.37 152.238 130.65 152.312 127.255L153.711 86.3494L171.401 83.2321L169.919 126.56C169.849 127.587 169.902 128.634 170.093 129.694C170.73 133.3 172.275 134.881 174.73 134.451C176.857 134.074 178.58 132.824 179.892 130.692C181.204 128.56 182.086 125.893 182.533 122.689L184.048 81.0048L201.738 77.8875L199.627 137.03C203.328 135.026 206.13 132.505 208.047 129.466C209.96 126.427 211.28 122.184 212.009 116.736L217.167 115.825C216.24 123.505 214.195 129.375 211.028 133.436C207.861 137.502 204.007 140.565 199.478 142.631L199.196 151.801C198.956 160.031 197.097 165.996 193.612 169.693C190.126 173.39 185.845 175.688 180.766 176.582C176.837 177.269 173.38 176.822 170.399 175.241ZM179.809 163.955C180.786 161.84 181.333 158.954 181.449 155.307L181.556 151.614C174.051 155.978 170.652 160.167 171.36 164.179C171.546 165.243 172.08 166.121 172.962 166.808C173.844 167.495 174.941 167.727 176.249 167.495C177.644 167.247 178.828 166.067 179.809 163.955Z" fill="white"/>
    <path d="M219.672 136.467C216.704 135.428 214.348 133.817 212.614 131.631C210.879 129.445 209.778 127.044 209.318 124.424C208.842 121.72 209.062 119.257 209.976 117.026C210.895 114.794 212.171 113.072 213.814 111.851C215.996 105.558 217.759 99.27 219.101 92.9939C220.442 86.722 221.58 79.9989 222.512 72.8245L240.26 67.1611C242.516 77.5729 245.215 88.792 248.349 100.822C249.624 105.662 250.456 109.189 250.849 111.4C251.731 116.397 251.181 120.715 249.206 124.358C247.231 128.001 244.503 130.845 241.021 132.894C237.54 134.943 233.876 136.305 230.026 136.984C226.093 137.68 222.644 137.51 219.672 136.467ZM231.52 123.931C233.251 121.77 233.76 118.682 233.052 114.666C232.617 112.211 231.765 108.812 230.494 104.478C228.026 95.2874 226.391 89.1149 225.584 85.9644C224.76 92.7828 222.806 101.104 219.726 110.936C221.783 111.586 222.98 112.853 223.311 114.737C223.584 116.293 223.319 117.775 222.512 119.183C221.705 120.59 220.521 121.435 218.968 121.708C217.25 122.01 216.05 121.675 215.371 120.698C215.892 123.646 216.865 125.711 218.302 126.895C219.734 128.079 221.758 128.439 224.383 127.976C227.409 127.442 229.79 126.092 231.52 123.931Z" fill="white"/>
    <path d="M46.1493 205.561C45.2096 205.213 43.9924 204.634 42.498 203.827C41.3802 203.218 40.4322 202.734 39.6539 202.378C38.8756 202.022 38.0476 201.748 37.1782 201.558C36.3088 201.367 35.4519 201.347 34.6115 201.496C34.3176 201.545 34.094 201.595 33.9408 201.645L34.7274 180.531C33.5103 181.289 32.6409 182.278 32.1152 183.487C31.5935 184.7 31.4693 186.087 31.7467 187.652C31.875 188.377 32.0324 188.869 32.2187 189.126C32.4049 189.387 32.5043 189.536 32.5084 189.573C31.4528 189.759 30.6248 189.676 30.0287 189.329C29.4325 188.981 29.0434 188.298 28.8654 187.279C28.6459 186.029 28.944 184.717 29.7637 183.355C30.5834 181.989 31.7301 180.801 33.2039 179.782C34.6777 178.764 36.2178 178.114 37.824 177.832C38.0973 177.783 38.4906 177.733 39.008 177.683L38.2049 199.558C38.7762 199.6 39.3765 199.699 39.9975 199.865C40.6226 200.026 41.3843 200.262 42.2868 200.564C43.6778 201.024 44.8577 201.355 45.8223 201.562C46.7869 201.765 47.7556 201.781 48.7367 201.608C49.6185 201.45 50.4134 201.152 51.1213 200.705C51.2165 202.162 51.0675 203.346 50.6742 204.265C50.2809 205.18 49.6848 205.71 48.8816 205.851C47.9957 206.008 47.0849 205.913 46.1493 205.561Z" fill="white"/>
    <path d="M54.8306 196.267C54.1227 195.625 53.6715 194.748 53.4727 193.63C53.3278 192.806 53.4231 192.098 53.7625 191.506C54.102 190.914 54.545 190.566 55.0914 190.471C56.1098 190.293 56.6894 190.612 56.8343 191.436C56.8964 191.788 56.8136 192.119 56.5859 192.434C56.3582 192.748 56.0477 192.939 55.6586 193.005C55.2487 193.075 54.9631 192.997 54.7975 192.765C54.9217 193.469 55.1577 193.986 55.5096 194.317C55.8614 194.648 56.321 194.764 56.8881 194.665C57.5547 194.549 57.9935 194.263 58.2046 193.812C58.4157 193.361 58.4323 192.628 58.2543 191.61C58.1508 191.022 57.9604 190.289 57.683 189.411C57.1572 187.565 56.6604 185.677 56.2009 183.74C55.1866 186.12 53.7832 187.76 51.9824 188.662C52.1811 190.484 52.0776 192.127 51.6719 193.589C51.2662 195.054 50.6204 196.234 49.7386 197.136C48.8568 198.039 47.8094 198.598 46.6006 198.809C45.1516 199.066 43.9428 198.896 42.9699 198.3C41.997 197.704 41.3678 196.607 41.0862 195C40.8461 193.651 40.8503 192.119 41.0945 190.413C41.3388 188.708 41.9515 187.155 42.9368 185.76C43.9221 184.365 45.342 183.504 47.2008 183.177C49.6848 182.738 51.2082 184.054 51.7713 187.122C53.2037 186.791 54.5243 184.953 55.7331 181.612L59.9723 180.258C60.5105 182.746 61.1563 185.429 61.9057 188.302C62.212 189.457 62.4107 190.301 62.5018 190.831C62.8123 192.591 62.5308 193.969 61.6573 194.959C60.7837 195.952 59.6412 196.569 58.2336 196.818C56.6729 197.095 55.5385 196.909 54.8306 196.267ZM49.0307 193.854C49.4529 192.578 49.6268 191.059 49.5606 189.296C49.0596 189.204 48.7491 188.803 48.625 188.099C48.4883 187.337 48.6746 186.778 49.1797 186.427C49.0307 185.806 48.8485 185.383 48.6374 185.16C48.4262 184.936 48.1364 184.853 47.7639 184.92C47.118 185.036 46.584 185.607 46.1617 186.642C45.7395 187.677 45.4621 188.886 45.3338 190.268C45.2054 191.651 45.2261 192.843 45.4041 193.841C45.5697 194.781 45.7891 195.394 46.0624 195.679C46.3356 195.965 46.7537 196.056 47.3209 195.957C48.0371 195.832 48.6084 195.129 49.0307 193.854Z" fill="white"/>
    <path d="M74.7931 196.987C74.2259 196.967 73.7002 196.768 73.22 196.387C72.7397 196.006 72.4168 195.348 72.2512 194.408C72.0194 193.096 72.1063 191.266 72.5079 188.915C72.9095 186.563 73.5594 184.158 74.4578 181.699C73.4891 181.931 72.7439 182.212 72.2181 182.548C71.6923 182.883 71.386 183.309 71.2949 183.831C71.3736 183.818 71.4729 183.901 71.5971 184.079C71.7213 184.261 71.8 184.456 71.8372 184.671C71.8993 185.023 71.7917 185.33 71.5143 185.59C71.237 185.851 70.8644 186.021 70.3924 186.108C69.846 186.203 69.3782 186.137 68.9931 185.901C68.6081 185.665 68.368 185.276 68.2728 184.725C68.1569 184.059 68.3059 183.409 68.7282 182.767C69.1505 182.129 69.7921 181.566 70.6574 181.082C71.5185 180.598 72.5079 180.254 73.6257 180.059C74.0355 179.989 74.5489 179.927 75.1657 179.877C76.3207 177.05 77.6538 174.674 79.1607 172.74C80.6676 170.811 82.2325 169.702 83.8553 169.416C84.795 169.25 85.5899 169.25 86.2481 169.416L85.4491 191.833L81.2223 192.578L81.6032 181.525C80.8208 181.318 80.1128 181.194 79.4794 181.144C78.846 181.094 78.1795 181.103 77.484 181.161C76.7927 183.765 76.3124 186.385 76.0392 189.026C75.7701 191.668 75.766 193.742 76.0309 195.249C76.1634 195.994 76.3952 196.515 76.7306 196.818C76.0102 196.954 75.3644 197.008 74.7931 196.987ZM81.9344 171.908C81.1726 172.508 80.444 173.498 79.7485 174.881C79.053 176.263 78.4279 177.903 77.8773 179.795C79.3345 179.819 80.5889 179.952 81.6405 180.192L81.9344 171.908Z" fill="white"/>
    <path d="M97.1857 188.968C96.515 188.43 96.0969 187.693 95.9313 186.754C95.8609 186.344 95.8236 185.872 95.8319 185.334C95.8361 184.8 95.8526 184.278 95.8733 183.769C95.894 183.26 95.9147 182.937 95.9271 182.792C95.9644 182.08 95.9892 181.376 96.0099 180.689C96.0306 180.002 96.0017 179.447 95.9313 179.037C95.7574 178.039 95.3144 177.605 94.6107 177.725C94.1015 177.816 93.6957 178.143 93.3935 178.71C93.0913 179.277 92.8926 179.985 92.7974 180.829L92.4621 190.604L88.2353 191.349L88.7569 176.122L92.9837 175.377L92.9299 176.959C93.7164 175.692 94.7638 174.938 96.0762 174.711C97.0739 174.537 97.9143 174.669 98.5974 175.112C99.2804 175.555 99.7193 176.338 99.918 177.451C100.017 178.019 100.059 178.66 100.046 179.381C100.034 180.101 99.9842 180.979 99.9056 182.022C99.8559 182.655 99.8186 183.272 99.7938 183.872C99.7689 184.473 99.7855 184.94 99.8435 185.272C99.9138 185.665 100.055 185.951 100.27 186.133C100.485 186.319 100.808 186.373 101.239 186.294C101.826 186.191 102.257 185.897 102.534 185.416C102.812 184.932 103.052 184.22 103.246 183.276L104.48 183.057C104.136 185.338 103.553 186.928 102.733 187.83C101.913 188.733 100.994 189.271 99.9759 189.453C98.7878 189.668 97.8563 189.503 97.1857 188.968Z" fill="white"/>
    <path d="M108.189 194.64C107.477 194.259 107.039 193.613 106.877 192.694C106.683 191.577 106.972 190.604 107.755 189.772C108.537 188.94 109.58 188.174 110.893 187.478L110.971 185.859C110.164 187.114 109.084 187.859 107.734 188.095C106.62 188.294 105.623 188.074 104.745 187.441C103.867 186.807 103.284 185.681 102.998 184.059C102.741 182.61 102.762 181.036 103.06 179.343C103.354 177.646 103.979 176.143 104.927 174.835C105.875 173.527 107.146 172.732 108.728 172.455C109.531 172.314 110.152 172.347 110.591 172.55C111.029 172.757 111.29 173.084 111.369 173.531L111.402 173.705L111.456 172.123L115.683 171.378L115.178 185.512C116.063 185.031 116.73 184.431 117.19 183.703C117.645 182.978 117.964 181.964 118.138 180.66L119.371 180.44C119.152 182.274 118.659 183.678 117.906 184.651C117.148 185.623 116.229 186.352 115.144 186.849L115.078 189.039C115.02 191.005 114.577 192.429 113.745 193.315C112.913 194.197 111.89 194.748 110.677 194.963C109.729 195.124 108.901 195.017 108.189 194.64ZM110.408 184.046C110.806 183.554 111.029 182.937 111.083 182.204L111.352 174.984C111.311 174.748 111.17 174.529 110.934 174.317C110.694 174.106 110.371 174.04 109.961 174.11C109.179 174.247 108.554 174.827 108.09 175.849C107.626 176.872 107.328 178.052 107.204 179.397C107.08 180.743 107.101 181.902 107.275 182.879C107.448 183.856 107.701 184.46 108.032 184.684C108.363 184.907 108.736 184.982 109.146 184.911C109.589 184.829 110.011 184.539 110.408 184.046ZM110.437 191.941C110.669 191.436 110.802 190.744 110.831 189.875L110.855 188.993C109.063 190.037 108.252 191.034 108.417 191.995C108.463 192.247 108.591 192.458 108.802 192.624C109.013 192.79 109.274 192.843 109.589 192.79C109.92 192.727 110.206 192.446 110.437 191.941Z" fill="white"/>
    <path d="M119.715 184.812C118.779 184.199 118.171 183.09 117.889 181.488C117.649 180.138 117.653 178.602 117.893 176.884C118.133 175.166 118.75 173.61 119.736 172.214C120.721 170.819 122.133 169.962 123.971 169.635C126.123 169.254 127.365 170.004 127.697 171.883C127.891 172.98 127.754 174.044 127.291 175.071C126.827 176.102 126.123 176.988 125.188 177.729C124.248 178.47 123.201 178.983 122.037 179.269C122.099 179.861 122.153 180.258 122.186 180.453C122.356 181.413 122.638 182.034 123.031 182.316C123.424 182.601 123.991 182.676 124.736 182.543C125.792 182.357 126.653 181.951 127.324 181.33C127.99 180.709 128.686 179.819 129.41 178.66L130.408 178.482C128.872 182.485 126.504 184.771 123.292 185.338C121.839 185.603 120.646 185.425 119.715 184.812ZM123.958 176.694C124.53 176.11 124.956 175.419 125.229 174.624C125.502 173.829 125.569 173.03 125.428 172.227C125.287 171.424 124.972 171.068 124.48 171.155C123.776 171.279 123.192 172.016 122.737 173.369C122.277 174.723 122.041 176.205 122.025 177.824C122.737 177.654 123.383 177.282 123.958 176.694Z" fill="white"/>
    <path d="M130.839 183.106C130.16 182.622 129.713 181.79 129.506 180.618C129.419 180.13 129.386 179.48 129.402 178.669L129.949 163.409L134.188 162.055L133.608 178.503C133.583 178.809 133.596 179.132 133.658 179.463C133.728 179.857 133.869 180.117 134.084 180.25C134.3 180.382 134.623 180.411 135.053 180.337C135.62 180.238 136.105 179.906 136.51 179.339C136.916 178.772 137.156 178.089 137.239 177.29L138.473 177.071C138.121 179.31 137.446 180.908 136.444 181.865C135.442 182.817 134.382 183.392 133.269 183.591C132.329 183.752 131.518 183.595 130.839 183.106Z" fill="white"/>
    <path d="M138.82 181.442C137.885 180.83 137.276 179.724 136.995 178.118C136.759 176.768 136.759 175.232 136.999 173.514C137.239 171.796 137.856 170.24 138.841 168.845C139.826 167.449 141.238 166.593 143.076 166.265C145.229 165.885 146.471 166.634 146.802 168.513C146.997 169.61 146.86 170.67 146.396 171.701C145.933 172.732 145.229 173.618 144.293 174.359C143.353 175.1 142.306 175.613 141.143 175.899C141.209 176.491 141.259 176.888 141.292 177.083C141.462 178.043 141.743 178.664 142.136 178.946C142.53 179.232 143.097 179.306 143.842 179.174C144.898 178.987 145.759 178.582 146.429 177.961C147.096 177.34 147.791 176.45 148.516 175.29L149.514 175.116C147.978 179.12 145.61 181.405 142.397 181.972C140.948 182.233 139.756 182.055 138.82 181.442ZM143.068 173.324C143.639 172.74 144.066 172.049 144.339 171.254C144.612 170.459 144.678 169.66 144.537 168.857C144.397 168.054 144.082 167.698 143.589 167.785C142.886 167.909 142.306 168.646 141.847 170C141.387 171.353 141.151 172.835 141.135 174.454C141.847 174.289 142.492 173.912 143.068 173.324Z" fill="white"/>
    <path d="M150.346 180.018C149.638 179.77 149.075 179.385 148.661 178.863C148.247 178.341 147.982 177.766 147.874 177.141C147.762 176.495 147.812 175.907 148.032 175.373C148.251 174.839 148.557 174.429 148.946 174.135C149.468 172.633 149.89 171.13 150.209 169.631C150.528 168.133 150.801 166.526 151.025 164.812L155.264 163.459C155.802 165.947 156.448 168.629 157.197 171.502C157.499 172.657 157.702 173.502 157.793 174.032C158.004 175.224 157.872 176.259 157.4 177.128C156.928 177.998 156.274 178.681 155.446 179.169C154.614 179.658 153.74 179.985 152.817 180.146C151.877 180.308 151.054 180.266 150.346 180.018ZM153.177 177.021C153.591 176.503 153.711 175.766 153.546 174.806C153.442 174.218 153.239 173.407 152.933 172.372C152.345 170.173 151.952 168.7 151.757 167.946C151.559 169.573 151.095 171.564 150.358 173.912C150.851 174.069 151.136 174.371 151.215 174.818C151.281 175.191 151.215 175.543 151.025 175.882C150.83 176.218 150.549 176.421 150.176 176.487C149.766 176.557 149.481 176.479 149.315 176.247C149.439 176.95 149.671 177.443 150.015 177.729C150.358 178.01 150.843 178.097 151.468 177.985C152.192 177.861 152.763 177.538 153.177 177.021Z" fill="white"/>
    </svg>
)

export default LogoSD