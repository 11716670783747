import * as React from "react"

const LogoVector = () => (
    <svg viewBox="0 0 220 208" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M55.7219 192.739C56.9657 193.485 58.4582 193.983 59.702 194.48C60.1995 194.729 60.1996 194.978 60.1996 195.476C60.1996 195.724 59.702 195.973 59.4533 195.973H59.2045C57.712 195.476 56.4682 194.729 54.9756 194.232C54.7269 193.983 54.4781 193.734 54.7269 193.237C54.9756 192.739 55.2244 192.49 55.7219 192.739Z" fill="white"/>
    <path d="M1.24357 120.599C0.994809 119.107 0.746062 117.614 0.497303 116.122C0.497303 115.624 0.746051 115.375 1.24357 115.375C1.74108 115.375 1.98985 115.624 1.98985 116.122C2.23861 117.614 2.48736 119.107 2.73611 120.599C2.73611 121.097 2.48736 121.345 2.23861 121.345C1.74109 121.345 1.24357 120.848 1.24357 120.599Z" fill="white"/>
    <path d="M4.97519 132.788C5.47271 132.788 5.72147 132.788 5.97023 133.286C6.46774 134.778 6.96526 136.022 7.46277 137.515C7.71153 138.012 7.46278 138.261 6.96526 138.51H6.71651C6.46775 138.51 6.21898 138.261 5.97023 138.012C5.47271 136.52 4.9752 135.276 4.47768 133.783C4.22892 133.286 4.47767 133.037 4.97519 132.788Z" fill="white"/>
    <path d="M3.98002 130.052C3.73126 130.052 3.23376 129.803 3.23376 129.554C2.73624 128.062 2.48748 126.569 2.23872 125.077C2.23872 124.579 2.48747 124.331 2.73623 124.082C3.23374 124.082 3.48251 124.331 3.73127 124.579C3.98002 126.072 4.47754 127.564 4.7263 128.808C4.7263 129.554 4.47754 129.803 3.98002 130.052Z" fill="white"/>
    <path d="M47.7615 188.51C49.0053 189.256 50.2491 190.003 51.4929 190.749C51.7416 190.998 51.9904 191.495 51.7416 191.744C51.4929 191.993 51.2441 192.241 50.9953 192.241H50.7466C49.5028 191.495 48.0102 190.749 46.7665 190.003C46.5177 189.754 46.2689 189.256 46.5177 189.008C47.0152 188.51 47.5127 188.261 47.7615 188.51Z" fill="white"/>
    <path d="M40.5474 183.535C41.7912 184.53 43.035 185.276 44.2788 186.023C44.5276 186.272 44.7763 186.769 44.5276 187.018C44.2788 187.267 44.0301 187.267 44.0301 187.267C43.7813 187.267 43.7813 187.267 43.5325 187.018C42.2887 186.272 41.0449 185.276 39.8011 184.281C39.5524 184.033 39.3036 183.535 39.5524 183.286C39.8011 183.535 40.2987 183.286 40.5474 183.535Z" fill="white"/>
    <path d="M198.509 110.151C198.758 109.902 199.255 109.902 199.504 110.151C200.499 111.146 201.743 112.39 202.489 113.634C202.738 113.883 202.738 114.38 202.24 114.629C201.992 114.629 201.992 114.878 201.743 114.878C201.494 114.878 201.245 114.878 201.245 114.629C200.25 113.385 199.504 112.39 198.26 111.395C198.26 110.897 198.26 110.4 198.509 110.151Z" fill="white"/>
    <path d="M33.8309 178.062C34.8259 179.057 36.0697 180.052 37.0648 181.047C37.3135 181.296 37.3135 181.794 37.0648 182.042C36.816 182.291 36.816 182.291 36.5673 182.291C36.3185 182.291 36.3185 182.291 36.0697 182.042C34.8259 181.047 33.8309 180.052 32.5871 179.057C32.3384 178.809 32.3384 178.311 32.5871 178.062C32.8359 177.813 33.3334 177.565 33.8309 178.062Z" fill="white"/>
    <path d="M190.798 105.425C191.046 104.928 191.295 104.928 191.793 104.928C193.285 105.425 194.529 106.171 195.773 106.918C196.022 107.166 196.27 107.664 196.022 107.913C195.773 108.161 195.524 108.161 195.524 108.161C195.275 108.161 195.275 108.161 195.027 108.161C193.783 107.415 192.539 106.669 191.295 106.171C190.798 106.42 190.798 105.923 190.798 105.425Z" fill="white"/>
    <path d="M0.248752 111.644C0.248752 110.151 -2.86102e-06 108.659 -2.86102e-06 107.166C-2.86102e-06 106.669 0.248763 106.42 0.74628 106.42C0.995039 106.42 1.49254 106.669 1.49254 107.166C1.49254 108.659 1.49256 110.151 1.74132 111.644C1.74132 112.141 1.49255 112.39 0.995035 112.39C0.497518 112.39 0.248752 112.141 0.248752 111.644Z" fill="white"/>
    <path d="M9.45262 69.1061H9.20386C8.9551 68.8573 8.70633 68.3598 8.95509 68.111C9.20385 67.6135 9.45261 67.116 9.70137 66.6185C9.95013 66.3697 10.4476 66.121 10.6964 66.3697C10.9452 66.6185 11.1939 67.116 10.9452 67.3648C10.6964 67.8623 10.4476 68.3598 10.1989 68.6086C9.95012 68.8573 9.70137 69.1061 9.45262 69.1061Z" fill="white"/>
    <path d="M2.23879 88.5092C2.73631 88.5092 2.98506 89.0067 2.7363 89.2555C2.48754 90.748 2.2388 92.2406 1.99004 93.7331C1.99004 93.9819 1.74127 94.4794 1.24376 94.4794C0.746238 94.4794 0.497491 93.9819 0.497491 93.7331C0.74625 92.2406 0.994997 90.748 1.24376 89.2555C1.49251 88.758 1.99003 88.5092 2.23879 88.5092Z" fill="white"/>
    <path d="M9.95018 146.719C9.70142 146.719 9.45267 146.47 9.20391 146.221C8.7064 144.977 7.96011 143.485 7.46259 141.992C7.21383 141.495 7.4626 141.246 7.96012 140.997C8.20888 140.749 8.70638 140.997 8.95514 141.495C9.45266 142.987 10.1989 144.231 10.6965 145.475C10.9452 145.724 10.6965 146.221 10.4477 146.47C9.95019 146.719 9.95018 146.719 9.95018 146.719Z" fill="white"/>
    <path d="M4.4775 80.0515C4.97501 80.0515 4.97501 80.549 4.97501 81.0465C4.47749 82.5391 4.22873 83.7829 3.73121 85.2754C3.73121 85.5242 3.23371 85.7729 2.98495 85.7729C2.98495 85.7729 2.98493 85.7729 2.73618 85.7729C2.23866 85.7729 1.98991 85.2754 2.23867 84.7779C2.48743 83.2853 2.98494 81.7928 3.48246 80.549C3.73122 80.0515 4.22874 79.8027 4.4775 80.0515Z" fill="white"/>
    <path d="M81.0953 201.197C82.5878 201.446 84.0804 201.943 85.3242 202.192C85.8217 202.192 86.0704 202.69 85.8217 203.187C85.8217 203.436 85.3242 203.685 85.0754 203.685C85.0754 203.685 85.0754 203.685 84.8267 203.685C83.3341 203.436 81.8416 202.938 80.349 202.69C79.8515 202.69 79.6027 202.192 79.8515 201.695C80.1003 201.197 80.5978 200.948 81.0953 201.197Z" fill="white"/>
    <path d="M89.8018 202.938C91.2943 203.187 92.7869 203.436 94.2794 203.684C94.7769 203.684 95.0257 204.182 94.7769 204.431C94.7769 204.679 94.5282 204.928 94.0306 204.928C92.5381 204.679 91.0455 204.431 89.553 204.182C89.0555 204.182 88.8067 203.684 89.0555 203.436C88.8067 203.187 89.3042 202.938 89.8018 202.938Z" fill="white"/>
    <path d="M0.995035 97.4648C1.49255 97.4648 1.74132 97.9623 1.74132 98.211C1.74132 99.7036 1.49254 101.196 1.49254 102.689C1.49254 103.186 1.2438 103.435 0.74628 103.435C0.248763 103.435 -2.86102e-06 103.186 -2.86102e-06 102.689C-2.86102e-06 101.196 -6.67572e-06 99.7036 0.248752 98.211C0.248752 97.7135 0.746276 97.4648 0.995035 97.4648Z" fill="white"/>
    <path d="M21.8905 164.878C22.8856 166.122 23.6318 167.117 24.6269 168.361C24.8756 168.61 24.8756 169.107 24.6269 169.356C24.3781 169.356 24.3781 169.605 24.1294 169.605C23.8806 169.605 23.6318 169.605 23.6318 169.356C22.6368 168.112 21.6418 167.117 20.8955 165.873C20.6467 165.624 20.6467 165.127 21.1442 164.878C21.1442 164.381 21.6418 164.381 21.8905 164.878Z" fill="white"/>
    <path d="M27.3633 171.595C28.3583 172.59 29.3534 173.834 30.3484 174.829C30.5972 175.077 30.5972 175.575 30.3484 175.824C30.0997 176.072 30.0996 176.072 29.8509 176.072C29.6021 176.072 29.3534 176.072 29.3534 175.824C28.3583 174.829 27.3633 173.585 26.3683 172.59C26.1195 172.341 26.1195 171.843 26.3683 171.595C26.617 171.346 27.1145 171.346 27.3633 171.595Z" fill="white"/>
    <path d="M16.9155 157.415C17.6618 158.659 18.408 159.903 19.4031 161.147C19.6518 161.395 19.6518 161.893 19.1543 162.142C18.9056 162.142 18.9056 162.39 18.6568 162.39C18.408 162.39 18.1593 162.39 18.1593 162.142C17.413 160.898 16.418 159.654 15.6717 158.41C15.4229 158.162 15.6717 157.664 15.9205 157.415C16.1692 157.167 16.6667 157.167 16.9155 157.415Z" fill="white"/>
    <path d="M189.803 179.057C189.554 179.306 189.554 179.306 189.305 179.306C189.056 179.306 189.056 179.306 188.808 179.057C188.559 178.809 188.559 178.311 188.808 178.062C189.803 177.067 190.798 175.823 191.793 174.828C192.041 174.58 192.539 174.58 192.788 174.828C193.036 175.077 193.036 175.575 192.788 175.823C191.793 176.818 190.798 178.062 189.803 179.057Z" fill="white"/>
    <path d="M12.4378 149.704C13.184 150.948 13.9303 152.44 14.4278 153.684C14.6766 153.933 14.4278 154.43 14.1791 154.679H13.9303C13.6816 154.679 13.4328 154.43 13.1841 154.181C12.4378 152.938 11.6915 151.445 10.9452 150.201C10.6965 149.953 10.9452 149.455 11.194 149.206C11.6915 149.206 12.189 149.455 12.4378 149.704Z" fill="white"/>
    <path d="M5.72144 77.3153H5.47268C4.97516 77.0665 4.97515 76.8178 4.97515 76.3203C5.47267 74.8277 5.9702 73.5839 6.71647 72.0914C6.96523 71.8426 7.21397 71.5938 7.71149 71.8426C8.20901 72.0914 8.20902 72.3401 7.96026 72.8376C7.46275 74.0814 6.71646 75.574 6.21894 76.8178C6.4677 77.0665 6.21895 77.3153 5.72144 77.3153Z" fill="white"/>
    <path d="M195.773 172.341C195.524 172.59 195.524 172.59 195.275 172.59C195.027 172.59 195.027 172.59 194.778 172.341C194.529 172.092 194.529 171.595 194.778 171.346C195.773 170.102 196.519 169.107 197.514 167.863C197.763 167.615 198.26 167.366 198.509 167.615C198.758 167.863 199.007 168.361 198.758 168.61C197.514 169.853 196.768 171.097 195.773 172.341Z" fill="white"/>
    <path d="M128.359 159.405C129.106 160.649 130.101 161.893 131.096 162.888C131.344 163.137 131.344 163.634 131.096 163.883C130.847 163.883 130.847 164.132 130.598 164.132C130.349 164.132 130.101 164.132 130.101 163.883C129.106 162.639 128.359 161.396 127.364 160.152C127.115 159.903 127.115 159.405 127.613 159.157C127.613 159.157 128.11 159.157 128.359 159.405Z" fill="white"/>
    <path d="M130.847 98.2108C130.847 98.7083 130.598 98.9571 130.349 98.9571C128.857 99.2058 127.613 99.7033 126.369 100.201H126.12C125.872 100.201 125.623 99.9521 125.374 99.7033C125.125 99.4546 125.374 98.9571 125.623 98.7083C126.867 97.962 128.359 97.7133 130.101 97.2158C130.598 97.7133 130.847 97.962 130.847 98.2108Z" fill="white"/>
    <path d="M200.997 164.878C200.748 165.127 200.499 165.127 200.499 165.127C200.25 165.127 200.25 165.127 200.002 165.127C199.753 164.878 199.504 164.381 199.753 164.132C200.499 162.888 201.246 161.644 201.992 160.401C202.241 160.152 202.738 159.903 202.987 160.152C203.236 160.401 203.484 160.898 203.236 161.147C202.489 162.391 201.743 163.634 200.997 164.878Z" fill="white"/>
    <path d="M77.1151 200.948C77.1151 201.197 76.6176 201.446 76.3689 201.446H76.1201C74.6276 200.948 73.135 200.699 71.8912 200.202C71.3937 200.202 71.3937 199.704 71.3937 199.207C71.3937 198.709 71.8912 198.709 72.3887 198.709C73.8813 199.207 75.1251 199.456 76.6176 199.953C77.1152 200.202 77.3639 200.451 77.1151 200.948Z" fill="white"/>
    <path d="M133.832 166.371C134.827 167.366 135.822 168.61 136.817 169.605C137.066 169.853 137.066 170.351 136.817 170.6C136.568 170.848 136.568 170.849 136.32 170.849C136.071 170.849 135.822 170.848 135.822 170.6C134.827 169.605 133.832 168.361 132.837 167.366C132.588 167.117 132.588 166.62 132.837 166.371C133.086 166.122 133.583 166.122 133.832 166.371Z" fill="white"/>
    <path d="M147.265 203.436C145.772 203.933 144.28 204.182 142.787 204.431C142.787 204.431 142.787 204.431 142.539 204.431C142.29 204.431 141.792 204.182 141.792 203.933C141.792 203.436 142.041 203.187 142.29 202.938C143.782 202.69 145.275 202.192 146.519 201.943C147.016 201.943 147.265 202.192 147.514 202.441C148.011 202.938 147.762 203.436 147.265 203.436Z" fill="white"/>
    <path d="M134.081 206.172C133.832 206.172 133.334 205.923 133.334 205.426C133.334 204.928 133.583 204.679 134.081 204.679C135.573 204.431 137.066 204.182 138.558 203.933C139.056 203.933 139.305 204.182 139.305 204.431C139.305 204.928 139.056 205.177 138.807 205.177C137.066 205.675 135.573 205.923 134.081 206.172Z" fill="white"/>
    <path d="M138.807 172.839C139.056 172.59 139.553 172.59 139.802 172.839C140.797 173.834 142.041 174.829 143.036 175.824C143.285 176.072 143.285 176.57 143.036 176.819C142.787 177.067 142.787 177.067 142.538 177.067C142.29 177.067 142.29 177.067 142.041 176.819C140.797 175.824 139.802 174.829 138.558 173.834C138.558 173.585 138.558 173.087 138.807 172.839Z" fill="white"/>
    <path d="M148.011 100.45C148.26 100.699 148.509 101.196 148.26 101.445C148.011 101.694 147.762 101.942 147.514 101.942H147.265C146.021 101.196 144.528 100.699 143.285 100.45C142.787 100.45 142.787 99.9523 142.787 99.4548C142.787 98.9572 143.285 98.9572 143.782 98.9572C145.026 99.206 146.519 99.7035 148.011 100.45Z" fill="white"/>
    <path d="M139.802 98.7083C139.802 98.9571 139.553 99.2058 139.056 99.2058C137.563 98.9571 136.071 98.7083 134.827 98.7083C134.329 98.7083 134.081 98.4595 134.081 97.962C134.081 97.4645 134.329 97.2158 134.827 97.2158C136.32 97.2158 137.812 97.4645 139.305 97.7133C139.553 97.962 139.802 98.2108 139.802 98.7083Z" fill="white"/>
    <path d="M122.638 151.694C122.887 151.445 123.384 151.694 123.633 151.943C124.379 153.187 125.125 154.43 125.872 155.674C126.12 155.923 125.872 156.42 125.623 156.669C125.623 156.669 125.374 156.669 125.125 156.669C124.877 156.669 124.628 156.669 124.379 156.42C123.633 155.177 122.887 153.933 122.14 152.44C122.14 152.44 122.389 151.943 122.638 151.694Z" fill="white"/>
    <path d="M151.494 202.192C151.245 202.192 150.996 201.943 150.748 201.694C150.748 201.197 150.748 200.948 151.245 200.699C152.738 200.202 153.981 199.704 155.474 199.207C155.971 198.958 156.22 199.207 156.469 199.704C156.718 200.202 156.469 200.451 155.971 200.699C154.479 201.197 152.986 201.694 151.494 202.192Z" fill="white"/>
    <path d="M172.141 192.988C170.897 193.734 169.653 194.48 168.161 195.227H167.912C167.663 195.227 167.414 194.978 167.166 194.729C166.917 194.48 167.166 193.983 167.414 193.734C168.658 192.988 169.902 192.242 171.146 191.495C171.394 191.247 171.892 191.495 172.141 191.744C172.638 192.242 172.389 192.739 172.141 192.988Z" fill="white"/>
    <path d="M166.668 112.142C166.419 112.142 166.17 111.893 165.922 111.644C165.922 111.146 165.922 110.898 166.419 110.649C167.663 110.4 168.658 109.654 170.151 108.659C170.399 108.41 170.897 108.41 171.146 108.908C171.394 109.156 171.394 109.654 171.146 109.903C169.653 110.898 168.409 111.644 166.668 112.142C166.917 112.142 166.917 112.142 166.668 112.142Z" fill="white"/>
    <path d="M187.315 103.684C187.813 103.684 188.061 104.181 187.813 104.679C187.813 104.927 187.315 105.176 187.066 105.176C187.066 105.176 187.066 105.176 186.817 105.176C185.325 104.927 183.832 104.679 182.589 104.679C182.091 104.679 181.842 104.43 181.842 103.932C181.842 103.435 182.091 103.186 182.589 103.186C184.33 103.435 185.822 103.435 187.315 103.684Z" fill="white"/>
    <path d="M103.483 205.675C103.483 206.172 103.235 206.421 102.737 206.421C101.245 206.172 99.7521 206.172 98.2595 205.923C97.762 205.923 97.5133 205.426 97.5133 205.177C97.5133 204.68 98.0108 204.431 98.2595 204.431C99.7521 204.68 101.245 204.928 102.737 204.928C103.235 204.928 103.483 205.426 103.483 205.675Z" fill="white"/>
    <path d="M165.673 190.749H165.424C163.932 190.252 162.688 189.505 161.195 189.008C160.947 188.759 160.698 188.51 160.947 188.013C161.195 187.764 161.444 187.515 161.942 187.764C163.185 188.262 164.678 189.008 165.922 189.505C166.419 189.754 166.419 190.003 166.419 190.5C166.419 190.5 166.171 190.749 165.673 190.749Z" fill="white"/>
    <path d="M158.21 186.52C157.962 186.769 157.713 187.018 157.464 187.018H157.215C155.971 186.272 154.479 185.525 153.235 184.779C152.986 184.53 152.738 184.033 152.986 183.784C153.235 183.535 153.733 183.286 153.981 183.535C155.225 184.281 156.469 185.028 157.962 185.774C158.459 185.774 158.459 186.272 158.21 186.52Z" fill="white"/>
    <path d="M145.772 178.56C146.021 178.311 146.519 178.062 146.767 178.311C148.011 179.306 149.255 180.052 150.499 180.799C150.747 181.047 150.996 181.545 150.747 181.794C150.499 182.042 150.25 182.042 150.25 182.042C150.001 182.042 150.001 182.042 149.752 181.794C148.509 181.047 147.265 180.052 146.021 179.306C145.524 179.555 145.524 179.057 145.772 178.56Z" fill="white"/>
    <path d="M125.125 206.918C124.628 206.918 124.379 206.67 124.379 206.172C124.379 205.675 124.628 205.426 125.125 205.426C126.618 205.426 128.111 205.177 129.603 205.177C130.101 205.177 130.349 205.426 130.349 205.923C130.349 206.421 130.101 206.67 129.603 206.67C128.111 206.67 126.618 206.918 125.125 206.918Z" fill="white"/>
    <path d="M157.962 108.41C158.21 108.161 158.708 107.913 158.957 108.161C160.449 109.156 161.444 109.903 162.688 110.151C163.185 110.4 163.185 110.649 163.185 111.146C163.185 111.395 162.688 111.644 162.439 111.644H162.19C160.947 111.146 159.703 110.4 158.21 109.405C157.713 109.156 157.713 108.908 157.962 108.41Z" fill="white"/>
    <path d="M159.703 199.207C159.454 199.207 159.205 198.958 158.956 198.709C158.708 198.212 158.956 197.963 159.454 197.714C160.698 197.217 162.19 196.471 163.434 195.973C163.683 195.724 164.18 195.973 164.429 196.222C164.678 196.471 164.429 196.968 164.18 197.217C162.937 197.963 161.444 198.461 159.952 198.958C159.952 199.207 159.952 199.207 159.703 199.207Z" fill="white"/>
    <path d="M155.474 106.669C155.225 106.918 155.225 106.918 154.976 106.918C154.728 106.918 154.728 106.918 154.479 106.669C153.484 105.923 152.24 104.928 150.996 103.933C150.748 103.684 150.499 103.186 150.748 102.937C150.996 102.689 151.494 102.44 151.743 102.689C152.986 103.684 154.23 104.43 155.225 105.425C155.723 105.923 155.723 106.42 155.474 106.669Z" fill="white"/>
    <path d="M204.728 157.415H204.479C203.982 157.415 203.733 156.918 203.982 156.42C204.479 155.177 204.977 153.684 205.474 152.191C205.474 151.694 205.972 151.694 206.469 151.694C206.967 151.694 206.967 152.191 206.967 152.689C206.469 154.182 205.972 155.674 205.474 156.918C205.226 157.415 204.977 157.415 204.728 157.415Z" fill="white"/>
    <path d="M175.623 190.749C175.375 190.749 175.126 190.749 175.126 190.5C174.877 190.252 174.877 189.754 175.375 189.505C176.618 188.759 177.862 187.764 179.106 187.018C179.355 186.769 179.852 186.769 180.101 187.267C180.35 187.515 180.35 188.013 179.852 188.262C178.608 189.257 177.365 190.003 176.121 190.749C175.872 190.749 175.623 190.749 175.623 190.749Z" fill="white"/>
    <path d="M120.897 149.206C120.648 149.206 120.399 148.958 120.15 148.709C119.653 147.465 118.906 146.221 118.409 144.977L118.16 144.729C117.911 144.231 118.16 143.982 118.658 143.734C118.906 143.485 119.404 143.734 119.653 144.231L119.902 144.48C120.399 145.724 120.897 146.967 121.643 148.211C121.892 148.46 121.643 148.957 121.394 149.206C121.145 149.206 121.145 149.206 120.897 149.206Z" fill="white"/>
    <path d="M68.6571 198.461C68.6571 198.709 68.1596 198.958 67.9108 198.958H67.6621C66.667 198.709 65.672 198.212 64.677 197.963C64.1794 197.714 63.9307 197.714 63.4332 197.466C62.9357 197.217 62.9357 196.968 62.9357 196.471C63.1844 195.973 63.4332 195.973 63.9307 195.973C64.4282 196.222 64.677 196.222 65.1745 196.471C66.1695 196.719 67.1646 197.217 68.1596 197.466C68.4083 197.714 68.6571 197.963 68.6571 198.461Z" fill="white"/>
    <path d="M174.877 107.415C174.877 107.415 174.628 107.415 174.38 107.415C174.131 107.415 173.882 107.166 173.633 107.166C173.385 106.918 173.633 106.42 173.882 106.171C175.375 105.176 176.618 104.679 178.111 104.181C178.608 103.932 178.857 104.181 179.106 104.679C179.355 105.176 179.106 105.425 178.608 105.674C177.365 105.923 176.121 106.669 174.877 107.415Z" fill="white"/>
    <path d="M182.589 185.276C182.34 185.276 182.091 185.276 182.091 185.027C181.842 184.779 181.842 184.281 182.091 184.032C183.335 183.037 184.33 182.042 185.325 181.047C185.574 180.799 186.071 180.799 186.32 181.047C186.569 181.296 186.569 181.794 186.32 182.042C185.325 183.037 184.081 184.032 182.837 185.027C183.086 185.276 182.837 185.276 182.589 185.276Z" fill="white"/>
    <path d="M207.216 125.326C207.713 125.326 207.962 125.574 208.211 125.823C208.459 127.316 208.708 128.808 208.957 130.301C208.957 130.798 208.708 131.047 208.211 131.047C207.962 131.047 207.464 130.798 207.464 130.301C207.216 128.808 206.967 127.316 206.718 126.072C206.469 125.823 206.718 125.574 207.216 125.326Z" fill="white"/>
    <path d="M203.982 117.117C204.231 116.868 204.728 117.117 204.977 117.365C205.723 118.609 206.221 120.102 206.718 121.594C206.967 122.092 206.718 122.34 206.221 122.589H205.972C205.723 122.589 205.474 122.34 205.226 122.092C204.728 120.599 204.231 119.355 203.484 118.112C203.484 117.614 203.484 117.117 203.982 117.117Z" fill="white"/>
    <path d="M207.216 148.958C206.718 148.958 206.469 148.46 206.469 147.963C206.718 146.47 206.967 144.977 207.216 143.734C207.216 143.236 207.713 142.987 207.962 142.987C208.459 142.987 208.708 143.485 208.708 143.734C208.459 145.226 208.211 146.719 207.962 148.211C207.962 148.709 207.713 148.958 207.216 148.958Z" fill="white"/>
    <path d="M208.46 134.281C208.957 134.281 209.206 134.53 209.206 135.027C209.206 135.773 209.206 136.271 209.206 137.017C209.206 138.012 209.206 138.758 209.206 139.754C209.206 140.251 208.957 140.5 208.46 140.5C207.962 140.5 207.713 140.251 207.713 139.754C207.713 139.007 207.713 138.012 207.713 137.266C207.713 136.768 207.713 136.022 207.713 135.525C207.713 134.53 207.962 134.281 208.46 134.281Z" fill="white"/>
    <path d="M106.468 205.923C106.468 205.426 106.717 205.177 107.215 205.177C108.707 205.177 110.2 205.426 111.692 205.426C112.19 205.426 112.439 205.675 112.439 206.172C112.439 206.67 112.19 206.918 111.692 206.918C110.2 206.918 108.707 206.67 107.215 206.67C106.717 206.67 106.468 206.421 106.468 205.923Z" fill="white"/>
    <path d="M122.887 101.694C123.135 101.942 123.135 102.44 122.887 102.689C121.892 103.684 120.897 104.679 120.15 105.923C119.902 106.171 119.653 106.171 119.653 106.171C119.404 106.171 119.404 106.171 119.155 106.171C118.906 105.923 118.658 105.425 118.906 105.176C119.902 103.932 120.897 102.689 121.892 101.694C122.14 101.445 122.389 101.445 122.887 101.694Z" fill="white"/>
    <path d="M115.424 206.421C115.424 205.923 115.673 205.675 116.17 205.675H117.663C118.658 205.675 119.653 205.675 120.648 205.675C120.897 205.675 121.394 205.923 121.394 206.421C121.394 206.918 121.145 207.167 120.648 207.167C119.653 207.167 118.906 207.167 117.911 207.167H117.663H116.17C115.673 207.167 115.424 206.918 115.424 206.421Z" fill="white"/>
    <path d="M117.663 140.749C117.663 140.749 117.663 140.997 117.663 140.749C117.165 140.749 116.916 140.5 116.668 140.251C116.17 138.759 115.673 137.266 115.424 136.022C115.424 135.525 115.673 135.276 115.921 135.027C116.419 135.027 116.668 135.276 116.916 135.525C117.165 137.017 117.663 138.261 118.16 139.754C118.409 140.251 118.16 140.749 117.663 140.749Z" fill="white"/>
    <path d="M115.673 118.36C115.424 119.853 115.424 121.345 115.424 122.838C115.424 123.336 115.175 123.584 114.677 123.584C114.18 123.584 113.931 123.336 113.931 122.838C113.931 121.345 114.18 119.853 114.18 118.36C114.18 117.863 114.677 117.614 114.926 117.614C115.424 117.365 115.673 117.863 115.673 118.36Z" fill="white"/>
    <path d="M117.414 108.907C117.911 109.156 117.911 109.405 117.911 109.902C117.414 111.146 116.916 112.639 116.419 114.131C116.419 114.38 115.921 114.629 115.673 114.629H115.424C114.926 114.629 114.678 114.131 114.926 113.634C115.424 112.141 115.921 110.649 116.419 109.405C116.668 108.907 117.165 108.659 117.414 108.907Z" fill="white"/>
    <path d="M114.677 131.545C114.429 130.052 114.18 128.56 114.18 127.067C114.18 126.569 114.429 126.321 114.926 126.321C115.424 126.321 115.673 126.569 115.673 127.067C115.921 128.56 115.921 130.052 116.17 131.545C116.17 132.042 115.921 132.291 115.673 132.291C115.673 132.291 115.673 132.291 115.424 132.291C114.926 132.291 114.677 132.042 114.677 131.545Z" fill="white"/>
    <path d="M219.902 189.256C220.151 189.754 219.902 190.003 219.405 190.252C217.912 190.749 216.669 191.247 215.176 191.744H214.927C214.678 191.744 214.43 191.495 214.181 191.247C213.932 190.749 214.181 190.5 214.678 190.252C216.171 189.754 217.415 189.257 218.907 188.759C219.156 188.759 219.654 189.008 219.902 189.256Z" fill="white"/>
    <path d="M201.992 195.227C200.499 195.475 199.007 195.724 197.514 195.724C197.017 195.724 196.768 195.475 196.768 194.978C196.768 194.48 197.017 194.232 197.514 194.232C199.007 194.232 200.499 193.983 201.992 193.734C202.489 193.734 202.738 193.983 202.738 194.48C202.738 194.729 202.489 194.978 201.992 195.227Z" fill="white"/>
    <path d="M210.947 193.237C209.455 193.734 207.962 193.983 206.469 194.232C206.469 194.232 206.469 194.232 206.221 194.232C205.972 194.232 205.474 193.983 205.474 193.734C205.474 193.237 205.723 192.988 205.972 192.739C207.465 192.49 208.957 191.993 210.201 191.744C210.698 191.744 210.947 191.744 211.196 192.242C211.445 192.739 211.196 193.237 210.947 193.237Z" fill="white"/>
    <path d="M189.803 195.724C189.305 195.724 189.056 195.724 188.559 195.724C188.061 195.724 187.813 195.475 187.813 194.978C187.813 194.48 188.31 194.232 188.559 194.232C189.056 194.232 189.305 194.232 189.803 194.232C190.549 194.232 191.295 194.232 192.041 194.232H192.788C193.285 194.232 193.534 194.48 193.534 194.978C193.534 195.475 193.285 195.724 192.788 195.724H192.041C191.295 195.724 190.549 195.724 189.803 195.724Z" fill="white"/>
    <path d="M178.857 193.734C178.857 193.237 179.355 192.988 179.604 193.237C181.096 193.485 182.589 193.734 184.081 193.983C184.579 193.983 184.827 194.48 184.827 194.729C184.827 195.227 184.579 195.475 184.081 195.475C182.589 195.227 181.096 194.978 179.604 194.729C179.106 194.729 178.857 194.232 178.857 193.734Z" fill="white"/>
    <path d="M60.4482 0.946227V1.19499L43.2839 52.9368C43.2839 53.1855 43.0352 53.4343 42.7864 53.4343L38.06 54.4293L32.3385 67.8623C32.3385 68.111 32.0898 68.111 32.0898 68.3598L18.1593 76.8176C17.9105 76.8176 17.9105 77.0663 17.6618 77.0663C17.413 77.0663 17.413 77.0663 17.1643 76.8176C16.9155 76.5688 16.6668 76.32 16.6668 76.0713L18.1593 58.1607C18.1593 57.9119 18.1593 57.9119 18.4081 57.6632L19.4031 56.1706L16.9155 56.4194C16.6668 56.4194 16.6668 56.4194 16.6668 56.4194L4.22884 50.4492C3.73132 50.2004 3.7313 49.9516 3.7313 49.4541L9.45276 30.5485C9.45276 30.2997 9.70151 30.2997 9.70151 30.051L59.2044 0.199951C59.2044 0.199951 59.2044 0.199951 59.4532 0.199951C59.4532 0.199951 59.4532 0.199951 59.702 0.199951C59.702 0.199951 59.702 0.448711 59.9507 0.448711C60.4482 0.697469 60.4482 0.697469 60.4482 0.946227C60.4482 0.697469 60.4482 0.946227 60.4482 0.946227ZM34.8261 37.5137L32.8361 62.3896L55.9706 7.91147L34.8261 37.5137ZM41.7914 51.9417L51.9905 21.5932L38.8062 52.688L41.7914 51.9417ZM30.846 67.116L33.0848 40.2501L19.9006 58.6582L18.6568 74.8275L30.846 67.116ZM33.3336 36.7674L38.06 30.2997L33.8311 35.7724L18.9056 54.6781L20.8956 54.4293L33.3336 36.7674ZM16.6668 54.4293L31.5923 35.5237L10.9453 31.5435L5.72138 48.9566L16.6668 54.4293ZM12.6866 30.2997L32.5873 34.0311L56.2193 3.93133L12.6866 30.2997Z" fill="white"/>
    </svg>
)

export default LogoVector