import * as React from "react"

const LogoTM = () => (
    <svg viewBox="0 0 268 88" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M28.3474 14.5014C28.0514 14.5014 27.7555 14.5014 27.4595 14.7973L2.60008 23.3798C1.71224 23.6757 1.12035 24.2676 0.824402 25.1555C0.824402 26.0433 0.824402 26.9311 1.12035 28.1149C1.41629 29.2987 2.00818 30.1865 2.89602 30.7784C3.48791 31.0744 3.78386 31.3703 4.37575 31.3703C4.6717 31.3703 4.96764 31.3703 5.26359 31.3703L13.2541 28.7068L24.2041 60.373C24.5001 61.2608 25.092 61.8527 26.2757 61.8527C26.5717 61.8527 26.8676 61.8527 27.1636 61.8527C28.0514 61.8527 28.6433 61.8527 29.5311 61.2608C30.7149 60.9649 31.6028 60.373 32.4906 59.4852C33.0825 58.5973 33.3784 57.7095 33.0825 56.8217L22.1325 25.1555L30.123 22.4919C31.0109 22.196 31.6028 21.6041 31.6028 20.7163C31.8987 19.8284 31.6028 18.6446 31.3068 17.7568C31.0109 16.573 30.419 15.9811 29.5311 15.3892C29.5311 14.7973 28.9392 14.5014 28.3474 14.5014Z" fill="white"/>
	<path d="M77.7703 3.84744C77.1784 3.84744 76.8824 3.84744 76.2905 4.14339C75.1067 4.43933 73.923 4.73528 73.0351 5.32717C72.1473 5.91906 71.8513 6.8069 71.8513 7.69474L74.5149 22.492L62.677 24.5636L60.0135 9.76636C59.7176 8.87852 59.1257 8.28663 58.2378 7.99068C57.6459 7.69474 57.054 7.69474 56.4622 7.69474C55.8703 7.69474 55.5743 7.69474 54.9824 7.99068C53.7986 8.28663 52.6149 8.58257 51.727 9.17447C50.8392 9.76636 50.5432 10.6542 50.8392 11.542L57.6459 49.719C57.9419 50.6069 58.5338 51.1988 59.4216 51.4947C60.0135 51.7907 60.6054 51.7907 61.4932 51.7907C62.0851 51.7907 62.3811 51.7907 62.973 51.7907C64.1568 51.4947 65.3405 51.1988 66.2284 50.3109C67.1162 49.719 67.4122 48.8312 67.1162 47.9434L64.1568 31.6663L75.9946 29.5947L78.954 45.8717C79.25 46.7596 79.8419 47.3515 80.7297 47.6474C81.3216 47.9434 81.9135 47.9434 82.8013 47.9434C83.3932 47.9434 83.6892 47.9434 84.2811 47.9434C85.4648 47.6474 86.6486 47.3515 87.5365 46.4636C88.4243 45.8717 88.7203 44.9839 88.4243 44.0961L81.3216 5.91906C81.0257 5.03123 80.4338 4.43933 79.5459 4.14339C78.954 3.84744 78.3621 3.84744 77.7703 3.84744Z" fill="white"/>
	<path d="M122.458 0C122.162 0 121.57 0 121.274 0L108.549 0.295946C107.661 0.295946 107.069 0.591892 106.477 1.18378C105.885 1.77567 105.589 2.36757 105.589 3.2554L107.069 42.0243C107.069 42.9121 107.661 43.8 108.549 44.0959C109.437 44.3919 110.324 44.6878 111.508 44.6878H111.804C112.988 44.6878 114.172 44.3919 115.06 43.8C115.947 43.2081 116.539 42.6162 116.243 41.7284L115.651 26.9311H119.795L128.673 42.9121C129.265 44.0959 130.153 44.6878 131.633 44.6878C131.633 44.6878 131.633 44.6878 131.928 44.6878C133.408 44.6878 134.592 44.0959 136.072 42.9121C137.255 41.7284 137.847 40.5446 137.847 39.3608L137.551 38.4729L129.561 25.1554C134.296 23.0838 136.664 18.9405 136.368 12.4297C136.368 4.14324 131.633 0 122.458 0ZM115.947 19.8284L115.651 8.58243L121.57 8.28648H121.866C123.346 8.28648 124.826 8.58243 125.714 9.47026C126.601 10.3581 127.193 11.8378 127.193 13.9095C127.193 15.9811 126.897 17.4608 126.01 18.3486C125.122 19.2365 123.642 19.8284 121.866 19.8284H115.947Z" fill="white"/>
	<path d="M163.595 0.88797C162.707 0.88797 161.819 1.18392 160.931 1.47986C160.043 1.77581 159.451 2.66364 159.451 3.55148L156.492 42.3204C156.492 43.2082 156.788 44.0961 157.676 44.6879C158.563 45.2798 159.747 45.5758 160.931 45.5758C161.227 45.5758 161.523 45.5758 161.523 45.5758C162.411 45.5758 163.299 45.2798 164.186 44.9839C165.074 44.6879 165.666 43.8001 165.666 42.9123L168.626 4.14337C168.626 3.25554 168.33 2.3677 167.442 1.77581C166.554 1.18392 165.37 0.88797 164.186 0.88797C164.186 0.88797 163.89 0.88797 163.595 0.88797Z" fill="white"/>
	<path d="M193.189 4.14331C192.301 4.14331 191.413 4.43926 190.526 4.7352C189.046 5.32709 188.158 5.91899 188.158 7.10277V7.69466L192.301 46.4636C192.301 47.6473 192.893 48.5352 194.077 49.1271C194.965 50.0149 196.445 50.3109 197.924 50.6068C198.516 50.6068 199.404 50.9027 199.996 50.9027C200.588 50.9027 201.476 50.9027 202.068 50.6068C203.251 50.3109 204.139 49.719 204.731 48.8311L222.784 14.5014V13.6136C223.08 12.4298 222.488 11.542 221.304 10.6541C220.12 9.76628 218.936 8.87844 217.457 8.87844C217.161 8.87844 216.569 8.87844 216.273 8.87844C215.089 8.87844 214.497 9.17439 213.905 10.0622L199.7 39.3609L197.332 6.80682C197.332 5.62304 196.445 4.7352 194.669 4.43926C194.373 4.14331 193.781 4.14331 193.189 4.14331Z" fill="white"/>
	<path d="M242.908 15.6853C242.612 15.6853 242.316 15.6853 241.724 15.6853C240.836 15.9812 240.245 16.5731 239.949 17.4609L227.223 54.1582C226.927 55.0461 226.927 55.9339 227.815 56.5258C228.407 57.4136 229.295 57.7096 230.478 58.3015L250.899 65.4042C251.195 65.4042 251.49 65.7001 251.786 65.7001C252.378 65.7001 252.97 65.4042 253.266 65.1082C253.858 64.5163 254.45 63.6285 255.042 62.7407C255.338 61.5569 255.634 60.669 255.338 59.7812C255.042 58.8934 254.45 58.3015 253.858 58.0055L238.469 52.6785L241.724 42.9123L250.307 45.8717C250.603 45.8717 250.899 46.1677 251.195 46.1677C251.786 46.1677 252.082 45.8717 252.674 45.5758C253.266 44.9839 253.858 44.392 254.154 43.5042C254.45 42.6163 254.45 41.7285 254.154 40.8407C253.858 39.9528 253.266 39.3609 252.378 39.065L243.796 36.1055L247.051 26.3393L262.44 31.6663C262.736 31.6663 263.032 31.6663 263.328 31.6663C263.92 31.6663 264.512 31.3704 264.808 31.0745C265.696 30.4826 265.992 29.5947 266.584 28.4109C267.176 26.9312 267.176 26.0434 267.176 25.1555C266.88 24.2677 266.584 23.6758 265.696 23.3799L245.276 16.2772C244.092 15.9812 243.5 15.6853 242.908 15.6853Z" fill="white"/>
	<path d="M82.5053 69.2514L81.6175 69.5473L81.0256 70.1392L80.7297 70.7311L80.1378 71.6189L79.8418 72.2108L79.5459 72.8027C79.2499 73.0987 79.2499 73.3946 78.954 73.9865C78.658 74.5784 78.3621 75.1703 78.0661 75.7622C77.7702 76.3541 77.4743 77.2419 77.1783 77.8338C76.8824 78.4257 76.5864 79.0176 76.2905 79.6095C75.9945 80.2014 75.9945 80.4973 75.6986 81.0892L75.4026 81.6811C75.1067 81.977 75.1067 82.5689 75.1067 83.1608C75.1067 83.7527 75.1067 84.3446 75.1067 84.6406C75.1067 85.2324 75.4026 85.5284 75.6986 86.1203L76.2905 87.3041H76.8824H77.1783L77.4743 87.0081L77.7702 86.4162L78.0661 85.5284V85.2324V84.9365V84.6406L78.3621 84.3446L78.658 84.0487H78.954V83.7527V83.4568V83.1608L79.2499 82.273L79.8418 81.6811V80.7933L80.1378 79.9054L80.4337 79.0176L80.7297 78.4257L81.0256 78.1297L81.3216 78.4257C82.2094 79.9054 83.3932 80.7933 84.8729 80.7933C85.4648 80.7933 86.0567 80.7933 86.6486 80.4973C87.2405 80.2014 87.8324 79.9054 88.4242 79.6095C89.0161 79.3135 89.608 79.0176 90.1999 78.4257C90.7918 78.1297 91.3837 77.5379 91.9756 77.2419V76.946H92.2715H92.5675V77.2419V77.5379C91.9756 79.6095 91.6797 81.3852 91.6797 82.5689C91.6797 84.0487 91.9756 84.9365 92.8634 85.5284H93.4553L94.0472 85.2324L94.3432 84.9365L94.9351 84.6406V84.3446V84.0487C95.231 83.1608 95.8229 82.273 96.1188 81.3852C96.4148 80.4973 96.7107 79.3135 96.4148 78.1297L96.7107 77.5379L97.0067 76.946V76.0581V74.8743L97.3026 73.9865V73.0987V72.5068H97.8945V72.2108V71.9149L97.5986 71.323V70.7311L97.0067 69.8433L96.7107 69.5473V71.027H96.4148L96.1188 70.4352L95.5269 70.1392H94.9351L94.3432 70.4352C94.0472 71.323 93.7513 71.9149 93.1594 72.5068C92.5675 73.0987 91.9756 73.3946 91.3837 73.9865H90.1999L89.3121 74.8743L89.0161 75.1703L88.4242 75.4662L87.8324 75.7622L86.9445 76.0581L86.3526 75.7622L86.0567 75.4662V74.8743V74.5784V74.2825V73.9865V73.6906V72.2108L85.1688 71.027L84.8729 70.7311L84.577 70.4352H83.9851H83.6891H83.3932L83.0972 70.1392V69.8433L82.5053 69.2514Z" fill="white"/>
	<path d="M110.324 69.5472L110.028 69.8432C109.732 70.4351 109.141 71.027 108.845 71.3229L107.365 72.5067H107.069H106.773H106.477H106.181L105.885 72.8026L105.293 73.6905L104.701 74.5783L104.405 75.4662L103.814 76.354V76.6499V76.9459L103.518 77.8337L103.222 78.7216C102.63 79.6094 102.334 80.4972 102.038 81.3851C101.742 82.2729 101.15 83.1607 100.854 84.0486V84.6405L100.558 84.9364L100.854 85.2324V85.5283V85.8243V86.1202V86.4162L101.15 86.7121L101.742 87.008H102.038H102.334H102.63H102.926V86.7121L103.222 86.4162L103.518 86.1202L104.405 85.8243V85.5283V84.9364L104.701 84.3445L104.997 83.7526V83.1607L105.293 82.8648V82.5689H105.589V82.2729V81.977L105.885 81.681L106.181 82.2729V82.8648L106.477 83.1607V83.4567H106.773H107.661H108.845L110.324 82.8648L111.212 82.5689H112.1L111.804 83.7526L110.916 84.3445L110.028 84.6405L110.62 85.2324L110.916 85.5283L111.212 85.8243L111.508 86.1202V86.4162V86.7121H111.804L112.1 87.008L112.396 87.304L112.988 87.5999L114.172 87.304L115.06 86.7121L115.947 85.8243L116.539 84.6405V83.4567L116.243 82.5689V82.2729V81.977L117.427 81.0891L118.315 80.2013L118.611 79.6094L118.315 79.3134L118.019 79.0175L117.427 78.7216H117.131V78.4256H116.835H116.243H115.947L114.468 76.9459L114.172 75.7621L113.876 74.8743L113.58 73.9864V72.8026L113.284 71.9148V71.6189V71.027V70.731L112.692 70.4351L111.804 70.1391L111.212 69.8432H110.916L110.324 69.5472ZM107.069 79.6094L107.661 78.7216L108.549 77.2418L109.732 75.7621V75.4662C110.028 76.058 110.028 76.6499 110.324 77.2418C110.62 77.8337 110.62 78.4256 110.62 79.0175C110.028 79.0175 109.437 79.3134 109.141 79.3134C108.549 79.3134 108.253 79.3134 107.661 79.3134H107.365L107.069 79.6094Z" fill="white"/>
	<path d="M129.857 69.2514C129.265 69.2514 128.673 69.5473 128.081 69.8433L126.897 71.323C126.601 71.9149 126.01 72.2108 125.714 72.8027C125.418 73.3946 124.826 73.6906 124.53 74.2825L123.938 73.9865H123.346L122.754 75.4662L122.458 76.946C122.458 77.5379 122.162 77.8338 122.162 78.4257C122.162 79.0176 121.866 79.3135 121.57 79.9054V80.2014V80.4973V80.7933H121.274V81.6811L120.978 82.5689L120.683 83.4568V84.3446H120.387L120.683 84.6406L120.978 84.9365V85.2324V85.5284V85.8243V86.1203L121.274 86.4162V86.7122H121.57H121.866H122.162H122.458L122.754 86.4162H123.346L123.938 85.2324C124.234 84.9365 124.234 84.3446 124.234 83.7527L125.122 84.6406L126.01 85.2324L128.081 85.8243C128.377 86.1203 128.969 86.1203 129.857 86.4162C131.337 87.0081 132.224 87.3041 133.112 87.6C133.408 87.6 134 87.6 134.296 87.6C134.592 87.6 134.888 87.6 135.184 87.3041L135.48 87.0081L135.776 86.7122L136.072 86.4162L136.368 86.1203H136.072L136.368 85.5284V84.9365L136.072 84.3446L135.776 83.7527H134.888H134.296L133.408 83.4568L132.224 83.1608L130.745 82.8649L129.857 82.5689L129.265 82.273L128.081 81.6811C128.673 81.3852 129.265 81.0892 130.153 81.0892C131.041 80.7933 131.633 80.4973 132.224 80.2014C132.816 79.9054 133.408 79.6095 134 79.0176C134.592 78.4257 134.888 77.8338 134.888 76.946V76.65V76.3541V76.0581V75.7622L134.592 74.8743L134 73.6906L133.704 72.8027L133.112 72.2108V71.9149L132.816 71.6189L132.52 71.323L131.337 70.1392L129.857 69.2514ZM126.601 77.5379L126.897 76.3541L127.489 75.4662L128.081 74.5784L128.673 73.6906L129.561 74.2825C129.857 74.5784 130.153 75.1703 130.153 75.7622C129.561 76.0581 128.969 76.3541 128.081 76.65C128.081 77.2419 127.489 77.5379 126.601 77.5379Z" fill="white"/>
	<path d="M144.95 69.5472L144.062 70.1391L143.766 71.027L143.47 71.9148L142.878 72.5067C142.582 73.3945 142.582 74.2824 142.286 74.8743C141.991 75.7621 141.695 76.354 141.399 77.2418C141.103 77.8337 140.807 78.7216 140.511 79.6094C140.215 80.4972 140.215 81.3851 139.919 82.2729H139.623V82.5689V82.8648V83.1607V83.4567L139.327 83.7526L139.919 84.0486V84.6405L140.215 85.2324L140.511 85.8243V86.4162L140.807 86.7121L141.103 87.008L141.399 87.304H141.991C142.582 87.008 143.174 86.7121 143.47 86.1202C143.766 85.5283 144.358 85.2324 144.654 84.6405C144.95 84.0486 145.246 83.4567 145.838 83.1607C146.134 82.5689 146.726 82.2729 147.318 81.977H147.614H147.909L148.797 82.8648L149.685 83.7526L150.573 84.3445L151.165 84.6405H151.461H151.757V84.9364C152.349 85.2324 152.941 85.2324 153.828 85.5283C154.42 85.5283 154.716 85.5283 155.604 85.5283C155.9 85.5283 156.196 85.5283 156.492 85.5283C157.084 85.2324 157.676 84.9364 157.972 84.3445C158.268 83.7526 158.564 83.1607 158.564 82.5689L157.972 82.2729L157.676 81.977L157.084 81.681L156.492 81.3851C155.9 81.3851 155.012 81.3851 154.42 81.3851C153.828 81.3851 153.236 81.3851 152.645 81.0891C152.053 81.0891 151.461 80.7932 151.165 80.4972C150.573 80.2013 150.277 79.6094 149.981 79.0175L150.277 78.7216L150.573 78.4256H150.869H151.165L151.461 78.1297L151.757 77.8337L152.053 77.5378L152.349 77.2418L153.532 76.354L154.716 75.7621L155.9 74.8743L157.084 73.9864L157.38 73.3945L157.676 73.0986L157.38 72.5067L157.084 72.2108L156.788 71.9148L156.492 71.3229L155.604 71.027L155.012 70.4351L154.42 70.731L153.532 71.027V71.3229V71.6189H153.236H152.941L152.053 72.2108L151.165 72.8026L150.277 73.3945L149.389 73.9864C148.797 74.2824 148.501 74.8743 147.909 75.1702C147.318 75.4662 146.726 76.058 146.43 76.354V75.7621L146.726 75.1702L147.022 74.5783L147.614 73.9864V73.6905V73.3945L148.205 72.8026V71.9148L147.614 71.027L147.022 70.4351H146.726L146.43 70.1391L146.134 69.8432H145.838H145.542H145.246L144.95 69.5472Z" fill="white"/>
	<path d="M175.432 69.5473L174.84 69.2514H174.545C173.657 69.2514 173.065 69.5473 172.473 69.5473C171.881 69.5473 171.289 69.8433 170.697 69.8433C170.105 69.8433 169.513 70.1392 168.922 70.1392C168.33 70.1392 167.738 70.4352 166.85 70.7311L165.666 71.027L164.482 71.323L163.595 71.9149L163.003 72.5068L163.299 73.3946V74.2825L163.595 75.1703L164.186 76.0581L163.595 77.2419L163.299 78.1297L163.003 79.0176L162.411 79.9054H162.115L161.819 80.2014V80.4973L161.523 80.7933L161.227 81.0892L160.931 81.3852V82.273V83.1608L161.227 84.0487V84.9365L161.523 85.2324L161.819 85.8243C162.115 86.1203 162.707 86.4162 163.299 86.7122C163.89 86.7122 164.482 87.0081 165.074 87.0081H165.37C165.962 87.0081 166.258 87.0081 166.85 87.0081C167.442 87.0081 168.034 86.7122 168.33 86.7122H168.626H168.922C169.513 86.7122 170.401 86.4162 170.697 86.4162C171.289 86.4162 171.881 86.1203 172.177 86.1203L173.361 85.2324C173.657 84.9365 173.953 84.3446 174.249 83.7527H173.953V83.4568V83.1608V82.8649H173.657L173.361 82.5689L173.065 82.273L172.769 81.977L171.289 82.273L169.809 82.5689H169.513C169.218 82.5689 168.626 82.5689 168.33 82.5689H167.146H165.962C165.962 82.5689 165.962 82.5689 165.666 82.5689C165.37 82.5689 164.778 82.5689 164.186 82.5689L164.778 81.0892L165.666 79.9054H166.554H167.442H168.034H168.626H168.922H169.218H170.401L171.585 79.6095L172.473 79.0176L173.361 78.4257V77.8338L173.065 77.2419H172.473L173.361 76.946L173.065 76.65L172.473 76.3541V76.0581V75.7622C171.585 75.7622 170.993 75.7622 170.105 76.0581C169.809 76.0581 169.218 76.0581 168.922 76.0581C168.626 76.0581 168.33 76.0581 168.034 76.0581V75.4662V74.8743H168.626L168.922 74.5784H169.513H169.809H170.105H170.401L171.585 73.9865L172.769 73.6906L173.953 73.3946L175.136 73.0987L176.024 72.5068L176.912 71.6189V71.323V71.027V70.7311V70.4352L176.024 69.8433L175.432 69.5473Z" fill="white"/>
	<path d="M195.261 69.2514C194.669 69.2514 194.077 69.2514 193.485 69.2514C192.893 69.2514 192.301 69.5473 191.709 69.5473L190.23 69.8433H189.046L188.75 69.5473H187.862H187.566V69.8433L187.27 70.1392C186.382 70.1392 185.79 70.1392 184.903 70.4352C184.311 70.4352 183.719 70.4352 182.831 70.7311C182.239 70.7311 181.647 70.7311 181.055 70.7311H180.759H180.463H180.167V71.027V71.323V71.6189V72.5068L180.463 73.0987V73.3946V73.6906V73.9865H180.759L181.055 74.2825L181.351 74.5784L181.943 74.8743H182.239H182.535H182.831H183.127H183.423H183.719H184.015H184.311H184.607H184.903H185.199H185.79H186.086H186.382V75.1703L186.086 75.4662L185.79 76.0581V76.65L185.495 76.946L185.199 77.2419L184.903 78.4257L184.311 79.0176C184.015 79.6095 183.719 80.4973 183.423 81.0892C183.127 81.977 183.127 82.8649 183.127 83.7527L183.423 84.0487V84.6406L183.719 85.2324L184.015 85.8243V86.1203H184.311L184.607 86.4162L184.903 86.7122L185.199 87.0081L185.495 87.3041H185.79C186.382 87.3041 186.974 87.0081 187.27 86.7122C187.566 86.4162 187.566 85.8243 187.566 85.5284L187.27 85.2324L186.974 84.9365V84.6406V84.3446V84.0487V83.7527V83.4568V83.1608V82.8649V82.5689V82.273L187.27 81.6811V81.3852L187.566 81.0892V80.7933L187.862 79.9054L188.454 79.0176L188.75 78.1297L189.342 77.2419L189.638 76.0581L189.934 75.1703L190.23 74.2825L190.526 73.0987H191.413H192.597C193.189 73.0987 193.781 72.8027 194.077 72.8027C194.669 72.8027 195.261 72.8027 195.557 72.8027H196.149H196.444H196.74H197.036H197.332H197.628H197.924L198.22 72.5068L198.516 72.2108V71.9149C198.516 71.323 198.516 71.027 198.22 70.7311L197.628 70.1392L197.332 69.8433L197.036 69.5473L196.74 69.2514H196.149H195.261Z" fill="white"/>
	<path d="M64.7488 76.3542C63.8609 76.3542 62.9731 76.6501 61.7893 76.6501C60.9015 76.6501 59.7177 76.6501 58.5339 76.6501C57.3501 76.6501 56.4623 76.6501 55.2785 76.6501C54.0947 76.6501 53.2069 76.6501 52.0231 76.6501C50.8393 76.6501 49.9515 76.6501 49.0636 76.6501C48.1758 76.6501 47.288 76.9461 46.4001 76.9461V77.5379L46.1042 78.1298L45.8082 78.7217V79.3136C46.4001 79.6096 47.288 79.6096 48.1758 79.9055C49.0636 79.9055 50.2474 80.2015 51.1353 80.2015C52.319 80.2015 53.2069 80.2015 54.3907 80.4974C55.2785 80.4974 55.8704 80.4974 56.7582 80.4974C57.0542 80.4974 57.6461 80.4974 57.942 80.4974C59.1258 80.4974 60.0136 80.4974 61.1974 80.4974C62.0852 80.4974 62.9731 80.4974 63.8609 80.2015L64.7488 76.3542Z" fill="white"/>
	<path d="M222.192 76.3542C221.304 76.3542 220.416 76.6501 219.232 76.6501C218.345 76.6501 217.161 76.6501 215.977 76.6501C214.793 76.6501 213.905 76.6501 212.722 76.6501C211.538 76.6501 210.65 76.6501 209.466 76.6501C208.282 76.6501 207.395 76.6501 206.507 76.6501C205.619 76.6501 204.731 76.9461 203.843 76.9461V77.5379L203.547 78.1298L203.251 78.7217V79.3136C203.843 79.6096 204.731 79.6096 205.619 79.9055C206.507 79.9055 207.691 80.2015 208.578 80.2015C209.466 80.2015 210.65 80.2015 211.834 80.4974C212.722 80.4974 213.314 80.4974 214.201 80.4974C214.497 80.4974 215.089 80.4974 215.385 80.4974C216.569 80.4974 217.457 80.4974 218.641 80.4974C219.528 80.4974 220.416 80.4974 221.304 80.2015L222.192 76.3542Z" fill="white"/>
    </svg>
)

export default LogoTM